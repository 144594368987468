import { CenterCard } from "components/shared/Styled";
import { ImgPlaceHolder } from "minUI/ImgPlaceHolder";

export const PyLion = () => {
  return (
    <CenterCard>
      <ImgPlaceHolder>PyLion Logo IMG</ImgPlaceHolder>
      PyLion is a Go language coach, inspired by{" "}
      <a href="https://www.apple.com/swift/playgrounds/">Swift Playgrounds</a>.
    </CenterCard>
  );
};
