// Header.tsx of [pab-web-workspace], at 210820

import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AccountBox } from "@material-ui/icons";
import BackgroundSwitcher from "./BackgroundSwitcher";
import LanguageSwitcher from "./LanguageSwitcher";
import { LogoSmall } from "../../shared/Logo";
import { RootStoreState } from "store/store";
import Search from "../../shared/SearchBar";
import { animName } from "store/anim/anim";
import { push } from "connected-react-router";
import { setHeaderLogoPosByRef } from "store/actions";
import styled from "styled-components";

const Header = ({ showLogo }: { showLogo: boolean }) => {
  const dispatch = useDispatch();
  const anim = useSelector((state: RootStoreState) => state.anim);
  const iconHeaderRef = useRef<HTMLImageElement>(null);

  const listenerWindowResize = useCallback(() => {
    dispatch(setHeaderLogoPosByRef(iconHeaderRef));
  }, [dispatch]);
  useEffect(() => {
    listenerWindowResize();
    return () => {
      window.removeEventListener("resize", listenerWindowResize);
    };
  }, [listenerWindowResize]);

  return (
    <HeaderWrap>
      <div className="header-left">
        <div style={{ opacity: showLogo && anim === animName.NONE ? 1 : 0 }}>
          <SmallLogoWrap
            onClick={() => {
              dispatch(push("/"));
            }}
          >
            <LogoSmall forwardedRef={iconHeaderRef} />
            <span style={{ height: "min-content" }}>
              Pablo <br /> LION
            </span>
          </SmallLogoWrap>
        </div>
      </div>
      <HeaderRightWrap>
        <Search />
        <BackgroundSwitcher />
        <LanguageSwitcher />
        <AccountBox className="button-mui-icon" />
      </HeaderRightWrap>
    </HeaderWrap>
  );
};

const HeaderWrap = styled.div`
  padding: 0 5%;
  height: 4rem;

  background-color: transparent;
  /* border-bottom: 0.0625rem solid var(--yellow2); */
  /* START gradient border bottom */
  padding-bottom: 1px;
  background: linear-gradient(
      to right,
      transparent 0%,
      var(--yellow1) 20%,
      var(--yellow1) 80%,
      transparent 100%
    )
    left bottom transparent no-repeat;
  background-size: 100% 1px;
  /* END gradient border bottom */

  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 1;
`;

const SmallLogoWrap = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  user-select: none;
`;

const HeaderRightWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  & > * {
    margin: 1rem;
  }
`;

export default Header;
