import { CenterCard } from "components/shared/Styled";
import { ImgPlaceHolder } from "minUI/ImgPlaceHolder";

export const ReasonableRating = () => {
  return (
    <CenterCard>
      <h1>NOT PLANNED YET</h1>
      <br />
      {/* <ImgPlaceHolder>QUESTION MARK</ImgPlaceHolder> */}
      <br />A Chrome Extension for Rating pages, YouTube videos, YouTube
      channels, online stores ETC.
      <ul>
        <li>Use correction system fit to norm.dist.(how to update)</li>
        <li>Show both video and channel rating on YouTube page</li>
      </ul>{" "}
    </CenterCard>
  );
};
