import { CenterCard } from "components/shared/Styled";
import { ImgPlaceHolder } from "minUI/ImgPlaceHolder";

export const ToolCraftsman = () => {
  return (
    <CenterCard>
      I'm fond of well-designed tools. They save time and cheer people.
      <br />
      <ImgPlaceHolder>HALF SOLID HALF LINE</ImgPlaceHolder>
      <br />
      Since I cannot operate a machine YET(buying a 3D printer), I craft digital
      tools for now.
      <br />
    </CenterCard>
  );
};
