// Tracker.tsx of [pab-web-workspace], at 210821

import { OrangeGlass } from "minUI/Glass";
import styled from "styled-components";

const Tracker = () => {
  return (
    <LeftBottomCard>
      I am a tracker PLACE HOLDER.
      <br />
      Lorem ipsum dolor sit , sed do ut et magna . @2021-08-21 10:49:26
    </LeftBottomCard>
  );
};

const LeftBottomCard = styled(OrangeGlass)`
  margin: 2rem;
  margin-left: 3rem;
  border-radius: 1rem;
`;

export default Tracker;
