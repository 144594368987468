import { CenterCard } from "components/shared/Styled";
import { ImgPlaceHolder } from "minUI/ImgPlaceHolder";

export const EducationalTools = () => {
  return (
    <CenterCard>
      <ImgPlaceHolder>Teacher IMG</ImgPlaceHolder>
      Education should be free and funny.
      <small>Education is not so funny as knowledge.</small>
      Interactive = funny (???)
    </CenterCard>
  );
};
