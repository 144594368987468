import { Action } from "types/redux";

/* ======== CONST ======== */
export const ANIM_types = {
  START_LOAD: "START_LOAD_ANIM",
  START_ENTER: "START_ENTER_ANIM",
  START_SHRINK_LOGO: "START_SHRINK_LOGO_ANIM",
  START_EXPAND_LOGO: "START_EXPAND_LOGO_ANIM",
  CLEAR: "CLEAR_ANIM",
};

const animDurationSec = {
  [ANIM_types.START_LOAD]: 1,
  [ANIM_types.START_ENTER]: 1,
  [ANIM_types.START_SHRINK_LOGO]: 1,
  [ANIM_types.START_EXPAND_LOGO]: 1,
};

export enum animName {
  LOAD = "LOAD",
  ENTER = "ENTER",
  SHRINK_LOGO = "SHRINK_LOGO",
  EXPAND_LOGO = "EXPAND_LOGO",
  NONE = "NONE",
}

/* ======== REDUCER ======== */
export const animReducer = (state: animName, action: Action) => {
  if (state === undefined) {
    return animName.LOAD;
  }
  switch (action.type) {
    case ANIM_types.START_LOAD:
      return animName.LOAD;
    case ANIM_types.START_ENTER:
      return animName.ENTER;
    case ANIM_types.START_SHRINK_LOGO:
      return animName.SHRINK_LOGO;
    case ANIM_types.START_EXPAND_LOGO:
      return animName.EXPAND_LOGO;
    case ANIM_types.CLEAR:
      return animName.NONE;
    default:
      return state;
  }
};

/* ======== ACTIONS ======== */
export const startLoadAnim = () => ({ type: ANIM_types.START_LOAD });
export const startEnterAnim = () => {
  return { type: ANIM_types.START_ENTER };
};
export const startShrinkLogoAnim = () => {
  return {
    type: ANIM_types.START_SHRINK_LOGO,
  };
};
export const startExpandLogoAnim = () => ({
  type: ANIM_types.START_EXPAND_LOGO,
});
export const clearAnim = () => ({ type: ANIM_types.CLEAR });

/* ======== MIDDLEWARE ======== */
export const animMiddleware =
  (store: any) => (next: any) => (action: Action) => {
    // console.log("animMiddleware by action: ", action);
    if (Object.values(ANIM_types).includes(action.type)) {
      // or use add a "isChained" property
      if (![(ANIM_types.CLEAR, ANIM_types.START_LOAD)].includes(action.type)) {
        setTimeout(() => {
          next(clearAnim());
        }, animDurationSec[action.type] * 1000);
      }
      switch (action.type) {
        case ANIM_types.START_LOAD:
          setTimeout(() => {
            // store.dispatch(startEnterAnim());
            animMiddleware(store)(next)(startEnterAnim());
          }, animDurationSec.load * 1000);
          return next(action);
        case ANIM_types.START_ENTER:
          return next(action);
        case ANIM_types.CLEAR:
          return next(action);
        default:
          return next(action);
      }
    } else {
      return next(action);
    }
  };
