// BackgroundSwitcher.tsx of [pab-web-workspace], at 210820
import MovieOutlined from "@material-ui/icons/MovieOutlined";
import MovieTwoTone from "@material-ui/icons/MovieTwoTone";
import { useDispatch, useSelector } from "react-redux";
import { toggleBcg } from "store/actions";
import { RootStoreState } from "store/store";

const BackgroundSwitcher = () => {
  const bcgOn = useSelector((state: RootStoreState) => state.bcgOn);
  const dispatch = useDispatch();
  const switchBackground = () => {
    dispatch(toggleBcg());
  };
  return bcgOn ? (
    <MovieOutlined className="button-mui-icon" onClick={switchBackground} />
  ) : (
    <MovieTwoTone className="button-mui-icon" onClick={switchBackground} />
  );
};

export default BackgroundSwitcher;
