export const ImgPlaceHolder = ({
  children,
  placeholderText,
}: {
  children?: string;
  placeholderText?: string;
}) => {
  return (
    <div
      style={{
        width: "320px",
        height: "320px",
        lineHeight: "calc(320px - 0.5rem)",
        textAlign: "center",
        border: "0.25rem solid var(--yellow1)",
        margin: "2rem auto",
      }}
    >
      {children ?? placeholderText ?? "IMAGE PLACEHOLDER"}
    </div>
  );
};
