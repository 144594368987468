import logo_large from "static/LION_1200.png";
export const LogoLarge = ({
  forwardedRef,
  isOpaque,
}: {
  forwardedRef?: React.RefObject<HTMLImageElement>;
  isOpaque?: boolean;
}): JSX.Element => (
  <img
    ref={forwardedRef}
    src={logo_large}
    alt="logo_LION"
    width="320"
    height="320"
    style={{ opacity: isOpaque ? 1 : 0 }}
  />
);

export const LogoSmall = ({
  forwardedRef,
}: {
  forwardedRef?: React.RefObject<HTMLImageElement>;
}) => (
  <div style={{ display: "inline" }}>
    <img
      ref={forwardedRef}
      style={{ width: "64px", height: "64px" }}
      src={logo_large}
      alt="logo_large"
    />
  </div>
);
