import { CenterCard } from "components/shared/Styled";

export const GamingCard = () => {
  return (
    <CenterCard>
      [ICON] Age of empires II: Definitive Edition: I made a discord bot
      (SpotterBot) to support gaming.
      <br />
      [ICON] DotA II: Legend rank, but I played full turbo these years.
      <br />
      [ICON(Corridor/Abalone game)] I also like play chess-like board games.
    </CenterCard>
  );
};
