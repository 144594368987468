import { CenterCard } from "components/shared/Styled";
import { ImgPlaceHolder } from "minUI/ImgPlaceHolder";

export const SnippetHub = () => {
  return (
    <CenterCard>
      <h1>ON MY PLAN #0</h1>
      <ImgPlaceHolder>SCREENSHOT</ImgPlaceHolder>
      SnippetHub[link]: Find, share and rate snippets of VSCode.
    </CenterCard>
  );
};
