// InputBoard.tsx of [pab-web-workspace], at 210820

import { useDispatch } from "react-redux";
import { locationAppend } from "store/actions";
import { useCallback } from "react";
import { OrangeGlassButton } from "minUI/Glass";
import styled from "styled-components";
import { Route } from "react-router";
import { cardRoutes } from "static/cardRoutes";
import { pathStr2OptionTitle } from "store/router/router";

const InputBoard = ({}: {}) => {
  const dispatch = useDispatch();
  // const location = useLocation();
  const handleOnClick = useCallback(
    (childName) => (evt: React.MouseEvent<HTMLButtonElement>) => {
      dispatch(locationAppend(childName));
    },
    //
    []
  );
  return (
    <InputBoardWrap className="lateral-part">
      {cardRoutes.map((node) => {
        return (
          <Route exact path={node.path} key={node.component.name}>
            {node.children &&
              Object.keys(node.children).map((childPathname: string) => (
                <OptionCard
                  key={pathStr2OptionTitle.get(childPathname) ?? ""}
                  onClick={handleOnClick(childPathname)}
                >
                  {pathStr2OptionTitle.get(childPathname) ?? ""}
                </OptionCard>
              ))}
          </Route>
        );
      })}
    </InputBoardWrap>
  );
};

const InputBoardWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const OptionCard = styled(OrangeGlassButton)`
  flex: 1 1 auto;
  margin: 2rem;
  margin-right: 3rem;
  width: 80%;
  border-radius: 1rem;
  font-size: xx-large;
`;

export default InputBoard;
