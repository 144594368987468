import { Action } from "types/redux";
import { Position } from "types/generic";
/* ======== CONST ======== */
export const REFS_types = {
  SET_HEADER_LOGO_POS: "SET_HEADER_LOGO_POS",
  SET_HEADER_LOGO_POS_BY_REF: "SET_HEADER_LOGO_POS_BY_REF",
  SET_MAIN_LOGO_POS: "SET_MAIN_LOGO_POS",
};

const initialLayout = {
  headerLogoPos: { x: 0, y: 0 },
  mainLogoPos: { x: 0, y: 0 },
};
/* ======== HELPER ======== */

/* ======== REDUCER ======== */

interface layoutInterface {
  headerLogoPos: Position;
  mainLogoPos: Position;
}

export const layoutReducer = (
  state: layoutInterface = initialLayout,
  action: Action
): layoutInterface => {
  switch (action.type) {
    case REFS_types.SET_HEADER_LOGO_POS:
      return { headerLogoPos: action.payload, mainLogoPos: state.mainLogoPos };
    case REFS_types.SET_MAIN_LOGO_POS:
      return {
        headerLogoPos: state.headerLogoPos,
        mainLogoPos: action.payload,
      };
    default:
      return state;
  }
};
/* ======== ACTIONS ======== */

export const setHeaderLogoPos = (imgPos: Position) => ({
  type: REFS_types.SET_HEADER_LOGO_POS,
  payload: imgPos,
});
export const setMainLogoPos = (imgPos: Position) => ({
  type: REFS_types.SET_MAIN_LOGO_POS,
  payload: imgPos,
});
export const setHeaderLogoPosByRef = (
  imgRef: React.RefObject<HTMLImageElement>
) => {
  const { x, y } = imgRef!.current! as { x: number; y: number };
  return {
    type: REFS_types.SET_HEADER_LOGO_POS,
    payload: { x, y },
  };
};
export const setMainLogoPosByRef = (
  imgRef: React.RefObject<HTMLImageElement>
) => {
  const { x, y } = imgRef!.current! as { x: number; y: number };
  return {
    type: REFS_types.SET_MAIN_LOGO_POS,
    payload: { x, y },
  };
};
/* ======== MIDDLEWARE ======== */
