import { useState } from "react";
import styled from "styled-components";
import { YellowGlass } from "./Glass";

export const Tooltip = ({
  tipText,
  tip,
  children,
}: {
  tipText?: string;
  tip?: string;
  children?: string;
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const showTooltip = () => {
    console.log("mouse entered");
    setIsVisible(true);
  };
  const hideTooltip = () => {
    setIsVisible(false);
  };
  return (
    <>
      <Tip onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
        {children ?? tip}
        {tipText && (
          <TooltipText style={{ visibility: isVisible ? "visible" : "hidden" }}>
            {tipText}
          </TooltipText>
        )}
      </Tip>
    </>
  );
};

const Tip = styled.span`
  position: relative;
  border-radius: 0.25rem;
  border-bottom: 1px dotted var(--orange0);
`;

const TooltipText = styled(YellowGlass)`
  position: absolute;
  z-index: 1000;
  left: 50%;
  border-radius: 1rem;
  padding: 0.5rem;
  top: calc(0.125rem + 100%);
  transform: translate(-50%, 0);
`;
