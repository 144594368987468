// StructureTree.tsx
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import styled from "styled-components";
import { titleArr2Pathname } from "store/router/router";

interface StructureTreeNodeProps {
  nodeName: string;
  depth: number;
  isLastNode: boolean;
  onClick: (nodeName: string) => any;
}
const StructureTreeNode = ({
  nodeName,
  depth,
  isLastNode,
  onClick,
}: StructureTreeNodeProps) => (
  <StructureTreeNodeWrap
    className={"structure-node"}
    style={{
      marginLeft: `${depth * 20 + 4}px`,
      opacity: isLastNode ? "1" : "",
      cursor: "pointer",
    }}
    onClick={() => {
      onClick(nodeName);
    }}
  >
    <span>{nodeName}</span>
  </StructureTreeNodeWrap>
);

const StructureTree = (props: { pathArr: string[] }) => {
  const dispatch = useDispatch();

  const switchToNode = (nodeName: string) => {
    const nodeInd = props.pathArr.indexOf(nodeName);
    let newPathArr;
    // validate new path in path
    // TODO: add a 404 card
    if (nodeInd !== -1) {
      newPathArr = props.pathArr.slice(0, nodeInd + 1);
    } else {
      newPathArr = props.pathArr;
      // TODO: add brothers nodes
    }
    const newPathname = titleArr2Pathname(newPathArr);
    dispatch(push(newPathname));
  };

  return (
    <StructureTreeWrap>
      {props.pathArr.map((nodeName, ind) => (
        <StructureTreeNode
          nodeName={nodeName}
          depth={ind}
          isLastNode={ind === props.pathArr.length - 1}
          onClick={switchToNode}
          key={nodeName}
        />
      ))}
    </StructureTreeWrap>
  );
};
const StructureTreeWrap = styled.div`
  color: var(--yellow0);
  whitespace: "pre-line";
`;

const StructureTreeNodeWrap = styled.div`
  position: relative;
  width: min-content;
  border-bottom: 3px solid var(--orange0);
  border-left: 3px solid var(--orange0);
  border-radius: 0 0 0 1rem / 0 0 0 0.75rem;
  padding: 0 0 0 0.25rem;
  opacity: 0.5;
  /* background-color: black; */
  z-index: 1;
  white-space: nowrap;
  color: var(--orange0);

  & span {
    z-index: 1;
    user-select: none;
  }

  &:hover {
    opacity: 1;
    box-shadow: -3px 3px 3px 0px var(--orange0);
  }

  &:hover > span {
    font-weight: 700;
    text-shadow: 0 0 16px var(--orange0);
  }
`;

export default StructureTree;
