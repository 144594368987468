import styled from "styled-components";

const yellowGlass = `
  background-color: var(--yellow0-a10);
  border: none;
  border-top: 1px solid var(--yellow0-a50);
  border-left: 1px solid var(--yellow0-a50);
  box-shadow: -1px -2px 12px 0 #ffde3350;
  backdrop-filter: blur(4px);
  z-index: 1;
`;
export const YellowGlass = styled.div`
  ${yellowGlass}
`;

export const orangeGlass = `
  background-color: var(--orange0-a10);
  border: none;
  border-top: 1px solid var(--orange0-a50);
  border-left: 1px solid var(--orange0-a50);
  box-shadow: -1px -2px 12px 0 #ffde3350;
  backdrop-filter: blur(4px);
  z-index: 1;
`;
export const OrangeGlass = styled.div`
  ${orangeGlass}
`;
export const OrangeGlassButton = styled.button`
  ${orangeGlass}
`;
