import { CenterCard } from "components/shared/Styled";
import { ImgPlaceHolder } from "minUI/ImgPlaceHolder";

export const LifetimeLearner = () => {
  return (
    <CenterCard>
      <ImgPlaceHolder> Einstein img </ImgPlaceHolder>
      “Wisdom is not a product of schooling but of the lifelong attempt to
      acquire it.”
      <br />
      -- Albert Einstein
    </CenterCard>
  );
};
