import { CenterCard } from "components/shared/Styled";
import { ImgPlaceHolder } from "minUI/ImgPlaceHolder";

export const HumanBeing = () => {
  return (
    <CenterCard>
      <ImgPlaceHolder placeholderText=" Cartoon PHOTO of me" />
      <ul>
        <li>Hobby: Gaming.</li>
        <li>Language: English, Chinese, Spanish.</li>
        <li>Religion: Considering becoming Catholic</li>
      </ul>
    </CenterCard>
  );
};
