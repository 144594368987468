import { CenterCard } from "components/shared/Styled";
import { ImgPlaceHolder } from "minUI/ImgPlaceHolder";

export const ChromeTerminal = () => {
  return (
    <CenterCard>
      <ImgPlaceHolder>SCREENSHOT + BORDER</ImgPlaceHolder>
      <a href="https://chrome.google.com/webstore/detail/chrome-terminal/ejmpoclnkkdepgggmppdbjpblncajlno">
        Chrome Terminal
      </a>{" "}
      is A Chrome Extension to execute customized commands in the style of
      Terminal/Command Prompt/Command Line/Power Shell
    </CenterCard>
  );
};
