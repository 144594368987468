import "./DynamicBcg.css";
import "material-icons/iconfont/outlined.css";

// import { AccessibilityNew, Pets } from "@material-ui/icons";
// import Icon from "@material-ui/core/Icon";
import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fortawesome/fontawesome-free/js/solid";

import "material-icons/iconfont/material-icons.css";
import React from "react";

const iconNames = ["dog", "cat", "bone", "paw"];
const iconObj = {
  dog: <i className="bcg-icon fas fa-dog" aria-hidden="true"></i>,
  cat: <i className="bcg-icon fas fa-cat" aria-hidden="true"></i>,
  bone: <i className="bcg-icon fas fa-bone" aria-hidden="true"></i>,
  paw: <i className="bcg-icon fas fa-paw" aria-hidden="true"></i>,
};
console.assert(iconNames.every((v) => v in iconObj));

const bcgSize = { row: 16, col: 8 };
const DynamicBcg = () => {
  /* <FloatingIcon cursorPos={[0, 0]} /> */
  return (
    <div className="bcg-dynamic" style={{ whiteSpace: "nowrap" }}>
      {[...Array(bcgSize.row)].map((unused, indRow) => (
        <div
          className={indRow % 2 ? "bcg-odd" : "bcg-even"}
          key={bcgSize.col * bcgSize.row + indRow}
        >
          {[...Array(bcgSize.col)].map((unused, indCol) => (
            <div
              style={{ display: "inline" }}
              key={bcgSize.col * indRow + indCol}
            >
              {iconObj.dog}
              {iconObj.cat}
              {iconObj.bone}
              {iconObj.paw}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
export default DynamicBcg;
