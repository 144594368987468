import React, { useState, useEffect } from "react";
import Header from "components/mainPage/header/Header";
import ShowBoard from "components/showBoardCards/ShowBoard";
import InputBoard from "components/mainPage/InputBoard";
import SideBar from "components/mainPage/SideBar";
import DynamicBcg from "components/global/DynamicBcg";
import TopLayerAnim from "components/global/TopLayerAnim";
import { useDispatch, useSelector } from "react-redux";
import { startLoadAnim, animName } from "store/anim/anim";
import { ConnectedRouter } from "connected-react-router";
import { history, RootStoreState } from "store/store";
import styled from "styled-components";
import { pathname2TitleArr } from "store/router/router";

function App() {
  const { bcgOn, anim }: { bcgOn: boolean; anim: animName } = useSelector(
    (state: RootStoreState) => ({ bcgOn: state.bcgOn, anim: state.anim })
  );
  const dispatch = useDispatch();
  const pathArr = pathname2TitleArr(history.location.pathname);
  const isRoot = history.location.pathname === "/";

  useEffect(() => {
    dispatch(startLoadAnim());
    return () => {
      console.log("APP unmounted");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppWrap>
      {(anim !== animName.NONE || true) && <TopLayerAnim />}
      {bcgOn && <DynamicBcg />}
      <Header showLogo={!isRoot} />
      <ConnectedRouter history={history}>
        <MainWrap>
          <SideBar pathArr={pathArr} />
          <ShowBoard />
          <InputBoard />
        </MainWrap>
      </ConnectedRouter>
    </AppWrap>
  );
}

const AppWrap = styled.div`
  width: 100vw;
  height: 100vh;
  color: white;
  text-align: center;

  display: flex;
  flex-direction: column;
`;

const MainWrap = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export default App;
