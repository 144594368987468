import { CenterCard } from "components/shared/Styled";

export const MathematicsLearning = () => {
  return (
    <CenterCard>
      My old major and current hobby.
      <br />I learned: [use left alignment]
      <ul>
        <li>Computational Geometry</li>
        <li>Latex and related packages</li>
        <li>And more</li>
      </ul>
      <br />
      Math and coding are very similar: Not need to know-how on everything, but
      know how to know-how.
      <br />
      <br />
      Math and program Trying to make a YouTube channel like{" "}
      <a href="https://www.youtube.com/c/3blue1brown">3B1B</a> with his python
      package.
    </CenterCard>
  );
};
