import { CenterCard } from "components/shared/Styled";

export const ProgramLearning = () => {
  return (
    <CenterCard>
      <p>
        My first exposure was Olympiad in secondary school. Then I was AFK for a
        long time.
      </p>
      <br />
      <p>
        Now, I'm a self-teaching programmer. All of my learning process are
        online, and mostly free.
      </p>
      <br />
      <p>
        Some materials:
        <br /> JavascriptES6:{" "}
        <a href="https://es6.ruanyifeng.com/#docs/">ruanyifeng</a>
        <br />
        Typecript / Python: Official document and{" "}
        <a href="https://stackoverflow.com/users/16223122/pablion">
          Stack Overflow
        </a>
      </p>
    </CenterCard>
  );
};
