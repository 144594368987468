import { applyMiddleware, combineReducers, createStore } from "redux";
import { bcgOnReducer } from "./bcgOn/bcgOn";
import { animReducer, animMiddleware } from "./anim/anim";
import { layoutReducer } from "./layout/layout";
import {
  routerReducer,
  history,
  enhancedRouterMiddleware,
} from "./router/router";
// !TODO webpack env here, conditional import? NEEDED? : i think not
import { composeWithDevTools } from "redux-devtools-extension";

export { history };
/* ======== CONFIGURE STORE ======== */

/* ==== REDUCERS ==== */

const reducers = combineReducers({
  router: routerReducer,
  bcgOn: bcgOnReducer,
  anim: animReducer,
  layout: layoutReducer,
});

/* ======== STORE ======== */
export const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(animMiddleware, enhancedRouterMiddleware))
);

/* ======== MIDDLEWARE ======== */
// NONE
/* ======== TYPING ======== */
export type RootStoreState = ReturnType<typeof store.getState>;

