import { YellowGlass } from "minUI/Glass";
import { RefObject } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { locationPop } from "store/actions";
import styled from "styled-components";

export const CenterCard = ({
  children,
  ref,
  ...rest
}: JSX.IntrinsicElements["div"]) => {
  const dispatch = useDispatch();
  const location = useLocation();
  return (
    <CenterCardStyle {...rest} ref={ref as RefObject<HTMLDivElement>}>
      {location.pathname !== "/" && (
        <>
          <button
            onClick={() => {
              dispatch(locationPop());
            }}
          >
            GO BACK
          </button>
          <br />
        </>
      )}
      {children}
    </CenterCardStyle>
  );
};

const CenterCardStyle = styled(YellowGlass)`
  flex-shrink: 0;
  flex-grow: 1;
  width: calc(40vw - 2rem);
  border-radius: 1rem;
  margin: 1rem;
  padding: 2rem;
  font-size: large;
  overflow: hidden;

  & > * {
    margin: 0.25rem auto;
  }
`;
