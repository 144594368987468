// TopLayerAnim.tsx of [pab-web-workspace], at 210822

import { useEffect, useState } from "react";

import { RootStoreState } from "store/store";
import { animName } from "store/anim/anim";
import logo_large from "static/LION_1200.png";
import styled from "styled-components";
import { useSelector } from "react-redux";

const TopLayerAnim = () => {
  const { anim, layout } = useSelector((state: RootStoreState) => {
    const { anim, layout } = state;
    return { anim, layout };
  });

  const [enterRunning, setEnterRunning] = useState<boolean>(false);
  const [shrinkRunning, setShrinkRunning] = useState<boolean>(false);
  const [expandRunning, setExpandRunning] = useState<boolean>(false);

  useEffect(() => {
    if (anim === animName.ENTER) {
      setEnterRunning(true);
    }
    if (anim === animName.SHRINK_LOGO) {
      setShrinkRunning(true);
    }
    if (anim === animName.EXPAND_LOGO) {
      setExpandRunning(true);
    }
    if (anim === animName.NONE) {
      setShrinkRunning(false);
      setExpandRunning(false);
      setEnterRunning(false);
    }
  }, [anim]);

  const logoAnimEl = (logoAnimName: animName) => {
    const animRunning =
      logoAnimName === animName.SHRINK_LOGO ? shrinkRunning : !expandRunning;
    return (
      <div
        style={{
          position: "fixed",
          left: `${animRunning ? layout.headerLogoPos.x : layout.mainLogoPos.x
            }px`,
          top: `${animRunning ? layout.headerLogoPos.y : layout.mainLogoPos.y
            }px`,
          transition: "top 1s ease-in-out, left 1s ease-in-out",
          zIndex: 1023,
        }}
      >
        <img
          src={logo_large}
          alt="logo_LION"
          width={`${animRunning ? "64" : "320"}px`}
          height={`${animRunning ? "64" : "320"}px`}
          style={{
            transition: "width 1s ease-in-out, height 1s ease-in-out",
          }}
        />{" "}
      </div>
    );
  };
  const logoEnterEl = () => {
    return (
      <div
        style={{
          position: "fixed",
          left: `${layout.mainLogoPos.x}px`,
          top: `${layout.mainLogoPos.y}px`,
          zIndex: 1023,
        }}
      >
        <img
          src={logo_large}
          alt="logo_LION"
          width="320px"
          height="320px"
          style={{
            transform: `${enterRunning ? "scale(1, 1)" : "scale(4, 4)"}`,
            transition: "transform 1s ease-in-out",
          }}
        />
      </div>
    );
  };
  console.log();
  return (
    <TopLayerAnimWrap>
      {process.env.NODE_ENV === "development" && (
        <div className="TopLayerAnim">
          {/* Im a top layer playing anim. Now playing: {anim} */}
        </div>
      )}
      {anim === animName.ENTER && logoEnterEl()}
      {anim === animName.SHRINK_LOGO && logoAnimEl(animName.SHRINK_LOGO)}
      {anim === animName.EXPAND_LOGO && logoAnimEl(animName.EXPAND_LOGO)}
    </TopLayerAnimWrap>
  );
};

const TopLayerAnimWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1023;
`;

export default TopLayerAnim;
