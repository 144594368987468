import { CenterCard } from "components/shared/Styled";

export const LifeSkills = () => {
  return (
    <CenterCard>
      <ul>
        <li>
          Meridian System(Chinese medicine): Body recognition + Show Acupuncture
          Points
        </li>
        <li>
          Taxonomy is the practice and science of categorization or
          classification
        </li>
        <li>AutoHotKey</li>
        <li>Typing skill</li>
        <li>Obsidian</li>
        <li>Hexo</li>
        <li>See more in my blog</li>
      </ul>
    </CenterCard>
  );
};
