import { Search as SearchIcon } from "@material-ui/icons";

const SearchBar = (props: any) => {
  return (
    <div style={{ display: "flex" }}>
      <SearchIcon />
      <div>SEARCH BAR</div>
    </div>
  );
};
export default SearchBar;
