import { CenterCard } from "components/shared/Styled";
import { ImgPlaceHolder } from "minUI/ImgPlaceHolder";

export const BlogCard = () => {
  return (
    <CenterCard>
      <ImgPlaceHolder>BLOG SCREENSHOT</ImgPlaceHolder>
      My Blog and thinking : (part of logo design)
      <br />
      <a href="https://pablion.github.io/">EPOB</a>
      <br />
      need to add: (new trinity: controller - robot - program, resurrection)
    </CenterCard>
  );
};
