import { CenterCard } from "components/shared/Styled";
import { ImgPlaceHolder } from "minUI/ImgPlaceHolder";

export const CodingTools = () => {
  return (
    <CenterCard>
      <ImgPlaceHolder>Recursive code gif</ImgPlaceHolder>
      Codes built for coding easier.
    </CenterCard>
  );
};
