import { startExpandLogoAnim, startShrinkLogoAnim } from "store/actions";
import { Action } from "types/redux";
import {
  connectRouter,
  routerMiddleware,
  LOCATION_CHANGE,
  push,
} from "connected-react-router";
import { createBrowserHistory } from "history";
/* ======== CONST ======== */
export const history = createBrowserHistory();

export const ROUTER_types = {
  LOCATION_CHANGE,
  LOCATION_APPEND: "@@router/LOCATION_APPEND",
  LOCATION_POP: "@@router/LOCATION_POP",
};

/* ======== HELPER ======== */
export function titleArr2Pathname(pathArr: string[]): string {
  return (
    "/" +
    pathArr.map((title: string) => optionTitle2PathStr.get(title)).join("/")
  );
}

export function pathname2TitleArr(pathname: string): string[] {
  const pathArr = pathname
    .split("/")
    .filter((p) => p !== "")
    .map((pathStr: string) => pathStr2OptionTitle.get(pathStr) ?? "");
  return pathArr;
}

const pathStrOptionTitleKVPairArr: [string, string][] = [
  ["root", "root"],
  ["human-being", "Human Being"],
  ["gaming", "Gaming"],
  ["language", "Language"],
  ["blog", "Blog"],
  ["tool-craftsman", "Tool Craftsman"],
  ["productivity-tools", "Productivity Tools"],
  ["chrome-terminal", "Chrome Terminal"],
  ["reasonable-rating", "Reasonable Rating"],
  ["coding-tools", "Coding Tools"],
  ["py-leet-runner", "py-leet-runner"],
  ["snippet-hub", "SnippetHub"],
  ["educational-tools", "Educational Tools"],
  ["allingo", "Allingo"],
  ["pylion", "PyLion"],
  ["golion", "GoLion"],
  ["lifetime-learner", "Lifetime Learner"],
  ["mathematics", "Mathematics"],
  ["coding", "Coding"],
  ["life-skills", "Life Skills"],
];
const reversedKVPairArr: [string, string][] = pathStrOptionTitleKVPairArr.map(
  ([k, v]) => [v, k]
);
export const pathStr2OptionTitle: Map<string, string> = new Map(
  pathStrOptionTitleKVPairArr
);

export const optionTitle2PathStr: Map<string, string> = new Map(
  reversedKVPairArr
);

/* ======== REDUCER ======== */
export const routerReducer = connectRouter(history);
/* ======== ACTIONS ======== */
export const locationAppend = (newPathNodeName: string) => {
  return {
    type: ROUTER_types.LOCATION_APPEND,
    payload: newPathNodeName,
  };
};
export const locationPop = () => {
  return { type: ROUTER_types.LOCATION_POP };
};
/* ======== MIDDLEWARE ======== */
export const enhancedRouterMiddleware =
  (store: any) => (next: any) => (action: Action) => {
    if (action.type === ROUTER_types.LOCATION_CHANGE) {
      console.log(action);
      const fromUrl = store.getState().router.location.pathname;
      const toUrl = action.payload.location.pathname;
      console.log("fromUrl, toUrl : ", fromUrl, toUrl);
      if (toUrl !== "/" && fromUrl === "/") {
        store.dispatch(startShrinkLogoAnim());
      }
      if (fromUrl !== "/" && toUrl === "/") {
        store.dispatch(startExpandLogoAnim());
      }
    }
    if (action.type === ROUTER_types.LOCATION_APPEND) {
      const fromUrl = store.getState().router.location.pathname;
      let newUrl = fromUrl + (fromUrl === "/" ? "" : "/") + action.payload;
      store.dispatch(push(newUrl));
    }
    if (action.type === ROUTER_types.LOCATION_POP) {
      const fromUrl = store.getState().router.location.pathname;
      const pathArr = pathname2TitleArr(fromUrl);
      console.log("pathArr : ", pathArr);
      pathArr.pop();
      const newUrl = titleArr2Pathname(pathArr);
      // let t = fromUrl + (fromUrl === "/" ? "" : "/");
      store.dispatch(push(newUrl));
    }
    return routerMiddleware(history)(store)(next)(action);
  };
