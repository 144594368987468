import { Action } from "types/redux";

/* ======== CONST ======== */
export const BCG_ON_types = { TOGGLE_BCG: "TOGGLE_BCG" };

/* ======== REDUCER ======== */

export const bcgOnReducer = (state: boolean = true, action: Action) => {
  switch (action.type) {
    case BCG_ON_types.TOGGLE_BCG:
      return !state;
    default:
      return state;
  }
};

/* ======== ACTIONS ======== */
export const toggleBcg = () => ({ type: BCG_ON_types.TOGGLE_BCG });

/* ======== MIDDLEWARE ======== */
