import { CenterCard } from "components/shared/Styled";

export const LanguageCard = () => {
  return (
    <CenterCard>
      [need more styling, add some bar chart maybe] <br />
      Chinese : Native <br />
      English : Advanced <br />
      Python : Advanced <br />
      TypeScript : Advanced <br />
      Spanish : Intermediate <br />
      C++ : Elementary <br />
    </CenterCard>
  );
};
