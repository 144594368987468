import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";

import { CenterCard } from "components/shared/Styled";
import ContactMe from "components/shared/ContactMe";
import { LogoLarge } from "components/shared/Logo";
import { RootStoreState } from "store/store";
import { Tooltip } from "minUI/Tooltip";
import { animName } from "store/anim/anim";
import { setMainLogoPosByRef } from "store/actions";
import styled from "styled-components";

interface RootCardProp {
  location: { pathname: string };
}
export const RootCard = ({ location: { pathname } }: RootCardProp) => {
  const anim: animName = useSelector((state: RootStoreState) => state.anim);
  const dispatch = useDispatch();
  const iconLargeRef: React.RefObject<HTMLImageElement> = useRef(null);

  const listenerWindowResize = () => {
    dispatch(setMainLogoPosByRef(iconLargeRef));
  };
  useEffect(() => {
    listenerWindowResize();
    if (pathname === "/") {
      window.addEventListener("resize", listenerWindowResize);
    }
    return () => {
      window.removeEventListener("resize", listenerWindowResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <CenterCard>
      <LogoWrap>
        <LogoLarge
          forwardedRef={iconLargeRef}
          isOpaque={
            anim === animName.NONE || anim === animName.ENTER // this line shall be moved to TopLayerAnim
          }
        />
        <span className="dimmed">
          Logo from <a href="https://pngtree.com/so/Polygonal">pngTree.com</a>
        </span>
        <button onClick={() => { }}>Design a logo for me(paid)</button>
      </LogoWrap>
      <ContactMe />
      <h1>
        Hi, I'm Pablo <Tooltip tipText="Lucky">L</Tooltip>
        <Tooltip tipText="Idiotic">I</Tooltip>
        <Tooltip tipText="Obedient">O</Tooltip>
        <Tooltip tipText="Noob">N</Tooltip>,
      </h1>
      <br />
      <br />
      <br />
      Select to know more about me as a {"->"}
      <br />
      {/* [arrow gif] */}
    </CenterCard>
  );
};

const LogoWrap = styled.div`
  margin: auto;
  width: 320px;
`;
