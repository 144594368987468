// SideBar.tsx of [pab-web-workspace], at 210822
import StructureTree from "./StructureTree";
import Tracker from "components/shared/Tracker";
import styled from "styled-components";

interface SideBarProps {
  pathArr: string[];
}
const SideBar = ({ pathArr }: SideBarProps) => {
  return (
    <SideBarWrap className="lateral-part">
      <StructureTree pathArr={pathArr} />
      <Tracker />
    </SideBarWrap>
  );
};

const SideBarWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export default SideBar;
