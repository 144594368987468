import { CenterCard } from "components/shared/Styled";
import { ImgPlaceHolder } from "minUI/ImgPlaceHolder";

export const Allingo = () => {
  return (
    <CenterCard>
      <ImgPlaceHolder>Allingo LOGO</ImgPlaceHolder>
      Allingo is a language coach that builds your muscle memory of using the
      language like typing, writing, listening, reading...
    </CenterCard>
  );
};
