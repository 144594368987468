import { CenterCard } from "components/shared/Styled";
import { ImgPlaceHolder } from "minUI/ImgPlaceHolder";

export const PyLeetRunner = () => {
  return (
    <CenterCard>
      <ImgPlaceHolder>SCREENSHOT</ImgPlaceHolder>
      py-leet-runner [link to github] is a public python repository for running{" "}
      <a href="https://leetcode.com/problemset/all/">Leetcode</a> tests locally.
    </CenterCard>
  );
};
