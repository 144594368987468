import React from "react";
import { connect } from "react-redux";
import { Route, withRouter, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import { cardRoutes } from "../../static/cardRoutes";
// import { logUpdatedDiff } from "devtools";

interface BoardState {}
interface BoardProp extends RouteComponentProps {}
class Board extends React.Component<BoardProp, BoardState> {
  state: BoardState;

  constructor(props: BoardProp) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    // console.log("this.props.match : ", this.props.match);
    // console.log("this.props.location : ", this.props.location);
    // console.log("this.props.history : ", this.props.history);
    // console.log("cardRoutes : ", cardRoutes);
    // if (this.props.isRoot) {
    // }
  }
  componentWillUnmount() {}
  componentDidUpdate(prevProps: BoardProp, prevState: BoardState) {
    // logUpdatedDiff(prevProps.location, this.props.location);
  }
  handlePathChange(event: React.MouseEvent, childName: string) {}

  render() {
    return (
      <CenterBoard>
        {cardRoutes.map((node) => {
          return (
            <>
              <Route
                exact
                path={node.path}
                component={node.component}
                key={node.component.name}
              />
            </>
          );
        })}
      </CenterBoard>
    );
  }
}

const CenterBoard = styled.div`
  width: 40vw;
  margin: 2rem;
  overflow-x: hidden;
  display: flex;
`;

export default withRouter(connect(null, {})(Board));
