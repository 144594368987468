import {
  Allingo,
  ChromeTerminal,
  EducationalTools,
  HumanBeing,
  LifetimeLearner,
  ProductivityTools,
  CodingTools,
  PyLeetRunner,
  PyLion,
  ReasonableRating,
  RootCard,
  ToolCraftsman,
  GamingCard,
  LanguageCard,
  BlogCard,
  SnippetHub,
  ProgramLearning,
  MathematicsLearning,
  LifeSkills,
} from "../components/showBoardCards";

type componentRef = (params: any) => JSX.Element;
interface dataNode {
  children?: { [k: string]: dataNode };
  component: componentRef;
  description: string;
}

const cardData: { root: dataNode } = {
  root: {
    description: "",
    component: RootCard,
    children: {
      "human-being": {
        component: HumanBeing,
        description: "Blog Is here",
        children: {
          gaming: {
            description: "",
            component: GamingCard,
          },
          language: {
            description: "",
            component: LanguageCard,
          },
          blog: {
            description: "",
            component: BlogCard,
          },
        },
      },
      "tool-craftsman": {
        description: "Tools I'm building",
        component: ToolCraftsman,
        children: {
          "productivity-tools": {
            description: "I'm a part-time tool craftsman",
            component: ProductivityTools,
            children: {
              "chrome-terminal": {
                component: ChromeTerminal,
                description:
                  "Chrome extension for using more keyboard shortcut",
              },
              "reasonable-rating": {
                component: ReasonableRating,
                description:
                  "[not started] Chrome extension for rating website/ youtube",
              },
            },
          },
          "coding-tools": {
            component: CodingTools,
            description:
              "[not started] Chrome extension for rating website/ youtube",
            children: {
              "py-leet-runner": {
                component: PyLeetRunner,
                description:
                  "[not started] Chrome extension for rating website/ youtube",
              },
              "snippet-hub": {
                component: SnippetHub,
                description:
                  "[not started] Chrome extension for rating website/ youtube",
              },
            },
          },
          "educational-tools": {
            component: EducationalTools,
            description: "Education should be free and funny",
            children: {
              allingo: {
                component: Allingo,
                description: "Language learning coach",
              },
              pylion: {
                component: PyLion,
                description: "[not started] Learn Python with interactive ",
              },
            },
          },
        },
      },
      "lifetime-learner": {
        component: LifetimeLearner,
        description: "My skill set",
        children: {
          mathematics: {
            component: MathematicsLearning,
            description: "[not started] Learn Go language with interactive ",
          },
          coding: {
            component: ProgramLearning,
            description: "[not started] Learn Go language with interactive ",
          },
          "life-skills": {
            component: LifeSkills,
            description: "[not started] Learn Go language with interactive ",
          },
        },
      },
    },
  },
};

interface Route {
  path: string;
  component: componentRef;
  children?: { [k: string]: dataNode };
}
export const cardRoutes: Route[] = [
  // here's one example
  // {
  //   path: "/",
  //   component: RootCard,
  //   children: /* child names */ ["a", "b", "c"],
  // },
];

function initializeRoutes() {
  function generateRoutes(node: dataNode, pathPrefix: string = "") {
    cardRoutes.push({
      path: pathPrefix,
      component: node.component,
      children: node.children ?? undefined,
      // ? Object.values(node.children).map(
      //   )
      // : [],
    });
    if (node.children !== undefined) {
      for (const childName in node.children) {
        generateRoutes(node.children[childName], pathPrefix + "/" + childName);
      }
    }
    return;
  }
  generateRoutes(cardData.root);
  if (cardRoutes[0].path === "") {
    cardRoutes[0].path = "/";
  }
}

initializeRoutes();
