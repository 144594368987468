import { CenterCard } from "components/shared/Styled";
import { ImgPlaceHolder } from "minUI/ImgPlaceHolder";

export const ProductivityTools = () => {
  return (
    <CenterCard>
      <ImgPlaceHolder>Mechanical key switch</ImgPlaceHolder>
      In my principle, productivity =
      <ul>
        <li>Working with only keyboard.</li>
        <li>Take note rather than memorize.</li>
        <small>More stable, sharable, editable.</small>
        <li>Computer is a way of taking and showing notes.</li>
      </ul>
    </CenterCard>
  );
};
